/*
  Syntax Highlighting
*/


// .highlight {
//   background: #f9f9f9; // Syntax highlighting background

//   .highlighter-rouge & {
//     background: #eef; // Highlighter-rouge specific background
//   }

//   .c     { color: #9fa0a6; font-style: italic } // Comment
//   .err   { color: #fff; background-color: #e05151 } // Error
//   .k     { color: #a625a4 } // Keyword
//   .o     { color: #383942 } // Operator
//   .cm    { color: #9fa0a6; font-style: italic } // Comment.Multiline
//   .cp    { color: #9fa0a6; font-style: italic } // Comment.Preproc
//   .c1    { color: #9fa0a6; font-style: italic } // Comment.Single
//   .cs    { color: #9fa0a6; font-style: italic } // Comment.Special
//   .gd    { color: #e05151 } // Generic.Deleted
//   .ge    { font-style: italic } // Generic.Emphasized
//   .gs    { font-weight: 700 } // Generic.Strong
//   .kc    { color: #a625a4 } // Keyword.Constant
//   .kd    { color: #a625a4 } // Keyword.Declaration
//   .kp    { color: #a625a4 } // Keyword.Pseudo
//   .kr    { color: #a625a4 } // Keyword.Reserved
//   .kt    { color: #a625a4 } // Keyword.Type
//   .m     { color: #b66a00 } // Literal.Number
//   .s     { color: #50a04f } // Literal.String
//   .na    { color: #b66a00 } // Name.Attribute
//   .nb    { color: #ca7601 } // Name.Builtin
//   .nc    { color: #ca7601 } // Name.Class
//   .no    { color: #ca7601 } // Name.Constant
//   .ni    { color: #ca7601 } // Name.Entity
//   .ne    { color: #ca7601 } // Name.Exception
//   .nf    { color: #383942 } // Name.Function
//   .nn    { color: #383942 } // Name.Namespace
//   .nt    { color: #e35549 } // Name.Tag
//   .nv    { color: #ca7601 } // Name.Variable
//   .ow    { font-weight: 700 } // Operator.Word
//   .w     { color: #f8f8f2 } // Text.Whitespace
//   .mf    { color: #b66a00 } // Literal.Number.Float
//   .mh    { color: #b66a00 } // Literal.Number.Hex
//   .mi    { color: #b66a00 } // Literal.Number.Integer
//   .mo    { color: #b66a00 } // Literal.Number.Oct
//   .sb    { color: #50a04f } // Literal.String.Backtick
//   .sc    { color: #50a04f } // Literal.String.Char
//   .sd    { color: #50a04f } // Literal.String.Doc
//   .s2    { color: #50a04f } // Literal.String.Double
//   .se    { color: #50a04f } // Literal.String.Escape
//   .sh    { color: #50a04f } // Literal.String.Heredoc
//   .si    { color: #50a04f } // Literal.String.Interpol
//   .sx    { color: #50a04f } // Literal.String.Other
//   .sr    { color: #0083bb } // Literal.String.Regex
//   .s1    { color: #50a04f } // Literal.String.Single
//   .ss    { color: #0083bb } // Literal.String.Symbol
//   .bp    { color: #ca7601 } // Name.Builtin.Pseudo
//   .vc    { color: #ca7601 } // Name.Variable.Class
//   .vg    { color: #ca7601 } // Name.Variable.Global
//   .vi    { color: #e35549 } // Name.Variable.Instance
//   .il    { color: #b66a00 } // Literal.Number.Integer.Long
//   .gu    { color: #75715e } // Generic.Subheading
//   .gd    { color: #e05151 } // Generic.Deleted
//   .gi    { color: #43d089 } // Generic.Inserted
//   .language-json .w + .s2 { color: #e35549 } // Language-specific (JSON) colors
//   .language-json .kc { color: #0083bb } // Language-specific (JSON) colors


// Copied from OneDarkJekyll (atom dark theme)

.highlight,
pre.highlight {
  background: #282c34;
  color: #abb2bf;
}

.highlight pre {
  background: #282c34;
}

.highlight .hll {
  background: #282c34;
}

.highlight .c {
  color: #5c6370;
  font-style: italic;
}

.highlight .err {
  color: #960050;
  background-color: #1e0010;
}

.highlight .k {
  color: #c678dd;
}

.highlight .l {
  color: #98c379;
}

.highlight .n {
  color: #abb2bf;
}

.highlight .o {
  color: #abb2bf;
}

.highlight .p {
  color: #abb2bf;
}

.highlight .cm {
  color: #5c6370;
  font-style: italic;
}

.highlight .cp {
  color: #5c6370;
  font-style: italic;
}

.highlight .c1 {
  color: #5c6370;
  font-style: italic;
}

.highlight .cs {
  color: #5c6370;
  font-style: italic;
}

.highlight .ge {
  font-style: italic;
}

.highlight .gs {
  font-weight: 700;
}

.highlight .kc {
  color: #c678dd;
}

.highlight .kd {
  color: #c678dd;
}

.highlight .kn {
  color: #c678dd;
}

.highlight .kp {
  color: #c678dd;
}

.highlight .kr {
  color: #c678dd;
}

.highlight .kt {
  color: #c678dd;
}

.highlight .ld {
  color: #98c379;
}

.highlight .m {
  color: #d19a66;
}

.highlight .s {
  color: #98c379;
}

.highlight .na {
  color: #d19a66;
}

.highlight .nb {
  color: #e5c07b;
}

.highlight .nc {
  color: #e5c07b;
}

.highlight .no {
  color: #e5c07b;
}

.highlight .nd {
  color: #e5c07b;
}

.highlight .ni {
  color: #e5c07b;
}

.highlight .ne {
  color: #e5c07b;
}

.highlight .nf {
  color: #abb2bf;
}

.highlight .nl {
  color: #e5c07b;
}

.highlight .nn {
  color: #abb2bf;
}

.highlight .nx {
  color: #abb2bf;
}

.highlight .py {
  color: #e5c07b;
}

.highlight .nt {
  color: #e06c75;
}

.highlight .nv {
  color: #e5c07b;
}

.highlight .ow {
  font-weight: 700;
}

.highlight .w {
  color: #f8f8f2;
}

.highlight .mf {
  color: #d19a66;
}

.highlight .mh {
  color: #d19a66;
}

.highlight .mi {
  color: #d19a66;
}

.highlight .mo {
  color: #d19a66;
}

.highlight .sb {
  color: #98c379;
}

.highlight .sc {
  color: #98c379;
}

.highlight .sd {
  color: #98c379;
}

.highlight .s2 {
  color: #98c379;
}

.highlight .se {
  color: #98c379;
}

.highlight .sh {
  color: #98c379;
}

.highlight .si {
  color: #98c379;
}

.highlight .sx {
  color: #98c379;
}

.highlight .sr {
  color: #56b6c2;
}

.highlight .s1 {
  color: #98c379;
}

.highlight .ss {
  color: #56b6c2;
}

.highlight .bp {
  color: #e5c07b;
}

.highlight .vc {
  color: #e5c07b;
}

.highlight .vg {
  color: #e5c07b;
}

.highlight .vi {
  color: #e06c75;
}

.highlight .il {
  color: #d19a66;
}

.highlight .gu {
  color: #75715e;
}

.highlight .gd {
  color: #f92672;
}

.highlight .gi {
  color: #a6e22e;
}
